<template>
        <div class="loginPanel">
                <div class="lpTitle">Регистрация</div>
                <div class="lp1">
                        <div class="lp1_text">Уже зарегистрированы?</div>
                        <buttonNone text='Войти!' @clickButton="gotoLogin"></buttonNone>
                </div>
                <input type="text" placeholder="Введите имя" v-model="name" class="name mt20"/>
                <input type="text" placeholder="Введите фамилию" class="fename" v-model="fename"/>
                <input type="text" placeholder="Введите электронную почту" class="email mt10" v-model="email"
                       :class="{error : errfield.indexOf('email') != -1}"/>
                <inputPassword placeholder="Создайте пароль" ref="ps" @updateps="updateps"></inputPassword>
                <inputPassword placeholder="Введите пароль повторно" ref="ps1" @updateps="updateps1"></inputPassword>
                <div class="dvError mt20" v-if="errors == null"></div>
                <div class="dvError viewError mt10" v-if="errors != null" v-for="(er, idxer) in errors" :key="idxer">
                        {{ er }}
                </div>
                <buttonRed class="binput mt30" text='Зарегистрироваться' @clickButton="startRegister"
                           :disable="isDisable"></buttonRed>
                <!--                <div class="desc mt20">Регистрируясь, вы подтверждаете, что принимаете
                                        <span class="href" @click="ifuse">Условия использования</span>, соглашаетесь с
                                        <span class="href" @click="police">Политикой конфиденциальности</span> и даете разрешение на обработку указанных вами данных-->
                <div class="descp mt20">Нажимая кнопку «Зарегистрироваться», я даю своё согласие на обработку моих персональных данных, в соответствии с федеральным законом
                        от 27.07.2006 года № 152-Ф3 «О персональных данных».
                </div>
        </div>
</template>

<script>
import ButtonRed from "../../common/ButtonRed"
import ButtonNone from "../../common/ButtonNone"
import InputPassword from "../../common/InputPassword"
import g from "../../globals";

export default {
        components: {ButtonRed, ButtonNone, InputPassword},

        data: function () {
                return {
                        name: "",
                        fename: "",
                        email: "",
                        ps: "",
                        ps1: "",

                        errors: null,
                        errfield: []
                }
        },

        computed: {
                isDisable() {
                        if (this.name.trim() == "") return true;
                        if (this.fename.trim() == "") return true;
                        if (this.email.trim() == "") return true;
                        if (!this.$refs.ps || this.$refs.ps.getPS().trim() == "") return true;
                        if (!this.$refs.ps1 || this.$refs.ps1.getPS().trim() == "") return true;
                        return false;
                }
        },

        methods: {
                gotoLogin() {
                        this.$emit("login");
                },
                async startRegister() {
                        this.name = this.name.trim();
                        this.fename = this.fename.trim();
                        this.email = this.email.trim();
                        this.errfield = [];
                        this.errors = null;
                        this.$refs.ps.clearError();
                        this.$refs.ps1.clearError();
                        try {
                                g.block(true);
                                await g.postUrl("auth/create", {
                                        name: this.name,
                                        fename: this.fename,
                                        email: this.email,
                                        ps: this.$refs.ps.getPS(),
                                        ps1: this.$refs.ps1.getPS()
                                });
                                g.block(false);
                                this.$emit("registerOk", this.email);
                        } catch (e) {
                                g.block(false);

                                if (typeof (e) == "object" && e.property == "register") {
                                        this.errfield = e.message.fields;
                                        this.errors = e.message.messages;
                                        if (this.errfield.indexOf("ps") != -1) this.$refs.ps.setError();
                                        if (this.errfield.indexOf("ps1") != -1) this.$refs.ps1.setError();
                                } else {
                                        this.errors = [e.message];
                                        this.errfield.push("email");
                                }
                        }
                },

                ifuse() {
                        this.$emit("ifuse");
                },
                police() {
                        this.$emit("police");
                },
                updateps() {
                        this.pc = this.$refs.ps.getPS();
                },
                updateps1() {
                        this.pc1 = this.$refs.ps1.getPS();
                }
        }
}
</script>
